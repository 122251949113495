@import 'styles/utils/mixins';
@import 'styles/utils/theme/animations';

.addFooterContainer {
  @include flex(row, space-between, flex-start);

  position: fixed;
  left: 0;
  top: calc(100vh - 105px);
  z-index: 99999;
  width: calc(100% - 70px);
  padding: 45px 35px;
  will-change: transform, opacity;
  pointer-events: none;

  @include phone {
    @include flex(row, center, center);

    top: 81vh;
    width: 100%;
    padding: 0;

    & > div,
    svg {
      display: none;
    }
  }
}

.animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.down {
  transform: translate3d(0, 40px, 0);
  opacity: 0;
  transition: transform $default-transition-time, opacity $default-transition-time;
}

.up {
  opacity: 1;
  transition: transform $default-transition-time, opacity $default-transition-time;
}

.notOnTop {
  opacity: 0;
}
