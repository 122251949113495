@import 'styles/utils/mixins';

.introTextContainer {
  @include fontSize(16px, 150%);
  @include flex;

  @include macbook-fifteen {
    text-align: center;
  }

  position: fixed;
  left: 50%;
  gap: 8px;
  width: max-content;
  top: calc(100vh - 60px);
  transform: translateX(-50%);

  @include macbook-twelve {
    @include fontSize(16px);
  }

  @include phone {
    @include flex(column, center, center);

    position: absolute;
    color: $gray0;
    opacity: 0.7;
    width: 100%;
    max-width: 340px;
    transform: translateY(-92px) translateX(-50%);
  }

  @include phone-height-500 {
    transform: translateY(-38px) translateX(-50%);
  }

  @include phone-height-600 {
    @include iOSDevice {
      transform: translateY(-98px) translateX(-50%);
    }
  }

  @include phone-height-650 {
    @include iOSDevice {
      transform: translateY(-128px) translateX(-50%);
    }

    transform: translateY(-68px) translateX(-50%);
  }

  @include phone-height-730 {
    @include iOSDevice {
      transform: translateY(-158px) translateX(-50%);
    }

    transform: translateY(-98px) translateX(-50%);
  }

  @include phone-height-800 {
    @include iOSDevice {
      transform: translateY(-228px) translateX(-50%);
    }

    transform: translateY(-178px) translateX(-50%);
  }
}

.introText {
  color: $gray0;
  margin: 0;

  @include phone {
    text-align: center;
    margin-bottom: 8px;
  }
}

.introLink {
  position: relative;
  color: $yellow500;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    bottom: -4px;
    width: 100%;
    background: $yellow500;
  }

  @include phone {
    font-weight: 600;
  }
}

.down {
  will-change: transform;
  transform: translate3d(-50%, 60px, 0);
  opacity: 0 !important;
  transition:
    transform $default-transition-time,
    opacity $default-transition-time;
}

.up {
  will-change: transform;
  transform: translate3d(-50%, 0, 0);
  opacity: 1;
  transition:
    transform $default-transition-time,
    opacity $default-transition-time;
}

.notOnTop {
  opacity: 0 !important;

  @include phone {
    opacity: 1 !important;
    transform: translateX(-50%) translateY(-50%);
  }
}
