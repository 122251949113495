@import 'styles/utils/mixins';
@import 'styles/utils/theme/_animations';

.iconWrapper {
  width: 35px;
  height: 50px;
}

.scrollDown {
  display: block;
  width: 32px;
  height: 27px;
  transform: rotate(180deg);
  animation: arrow-down 1.5s;
  animation-iteration-count: infinite;

  path {
    fill: $gray0;
  }
}
